import { debounce } from '../../../../../Frontend/js/util/debounce';

const bodyElement = document.querySelector("body")!;
const header = document.querySelector(".header") as HTMLElement;
const headerHeight = header.clientHeight;
let isHeaderFixed = false;

/* Media query for desktop */
const desktopMediaQuery = window.matchMedia("(min-width: 1260px)");

function clearHeaderFixed(): void {
  header.classList.remove("header--fixed");
  bodyElement.classList.remove("padding-top");
  isHeaderFixed = false;
}

function makeHeaderFixed(): void {
  header.classList.add("header--fixed");
  bodyElement.classList.add("padding-top");
  isHeaderFixed = true;
}

const toggleHeaderFixed = debounce((): void => {
  if (bodyElement.classList.contains("menu-is-open")) {
    return;
  }

  if (window.scrollY > headerHeight && !isHeaderFixed) {
    makeHeaderFixed()
  }
  else if (window.scrollY <= headerHeight && isHeaderFixed) {
    clearHeaderFixed();
  }
}, 100);

const blub = (event: MediaQueryListEvent | MediaQueryList): void => {
  if (event.matches) {
    if (window.scrollY > headerHeight && !isHeaderFixed) {
      makeHeaderFixed();
    }
    window.addEventListener("scroll", toggleHeaderFixed);
  } else {
    if (isHeaderFixed) {
      clearHeaderFixed();
    }
    window.removeEventListener("scroll", toggleHeaderFixed);
  }
}

desktopMediaQuery.addEventListener('change', blub);
blub(desktopMediaQuery);
